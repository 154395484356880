import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PoliticaPrivacidadeComponent } from '../shared/footer/politica-privacidade/politica-privacidade.component';

const routes: Routes = [
  {
    path: "",
    component: IndexComponent,

  },

  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
