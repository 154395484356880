<section class="section" id="location">
  <div class="col-12 p-0">
    <div class="row"></div>

    <div class="text-center mb-5">
      <h3 class="title mb-3 text-azul-sim font-weight-bold" style="font-family: Bebas Neue; font-size: 2.2rem">
        Onde Estamos
      </h3>
      <p class="text-muted font-size-15"></p>
    </div>

    <br />

    <div class="combos">
      <!-- todos  -->
      <div class="form-check form-check-inline">
        <button class="btn btn-primary" type="checkbox" id="inlineCheckbox1" value="option1"
          (click)="mapa(unidades, zoom, latitude, longitude, true)">
          Todos
        </button>
      </div>
      <!-- estado  -->
      <div class="form-check form-check-inline" id="selecionarEstado">
        <select class="form-select custom-select form-select-sm" aria-label=".form-select-sm example"
          [(ngModel)]="filtroEstados" (ngModelChange)="filtroPesquisaEstado($event)" name="filtro2">
          <option [ngValue]="null" [disabled]="true">
            Selecione um estado
          </option>
          <option [value]="item.sigla" *ngFor="let item of estados" (click)="filtroPesquisaEstado(item.sigla)">
            {{ item.nome }}
          </option>
        </select>
        <br />
      </div>
      <!-- cidade  -->
      <div class="form-check form-check-inline" id="cidade">
        <select class="form-select custom-select form-select-sm" aria-label=".form-select-sm example"
          [(ngModel)]="filtroCidade" [disabled]="!filtroEstados" (ngModelChange)="filtroPesquisaCidade($event)"
          name="filtro2" id="filtroCidade">
          <option [ngValue]="null" [disabled]="true">Selecione uma cidade</option>
          <option [value]="item.nome" *ngFor="let item of cidadesCombo" (click)="filtroPesquisaCidade(filtroCidade)">
            {{ item.nome }}
          </option>
        </select>
        <br />
      </div>
      <!-- nome  -->
      <div class="form-check form-check-inline">
        <select class="form-select custom-select form-select-sm" aria-label=".form-select-sm example"
          [(ngModel)]="filtroNome" (ngModelChange)="filtroPesquisaNome($event)" name="Nome Filial">
          <option [ngValue]="null" [disabled]="true">
            Selecione uma Unidade
          </option>
          <option [value]="item.dadosUnidade.nome" *ngFor="let item of unidades"
            (click)="filtroPesquisaNome(filtroNome)">
            {{ item.dadosUnidade.nome }}
          </option>
        </select>
        <br />
      </div>
    </div>
    <br />

    <div class="full" id="map"></div>
  </div>

  <!-- mapas -->
</section>
