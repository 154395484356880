import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-video-modal",
  template: `
    <div id="popup-fimano">
      <span class="btn-close" (click)="closeModal()" aria-hidden="true">
        <img src="../../../assets/images/btn-close.png" alt="Fechar" />
      </span>
      <iframe
        class="embed-responsive"
        src="https://www.youtube.com/embed/-I-V7AMOAGs"
        style="border:none"
        allowfullscreen
      ></iframe>
    </div>
  `,
})
export class VideoModalComponent implements OnInit {
  closeResult = "";
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close();
  }
}

@Component({
  selector: "app-features",
  templateUrl: "./features.component.html",
  styleUrls: ["./features.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeaturesComponent implements OnInit {
  closeResult = "";
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.modalService.open(VideoModalComponent, {
      backdrop: "static",
      size: "xl",
      windowClass: "custom-class",
      keyboard: false,
      centered: true,
    });
  }
}
