<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo" href="/">
      <img src="assets/images/logo-distri.png" alt="" class="logo-dark" height="100" />
      <img src="assets/images/logo-distri.png" alt="" class="logo-light" height="800" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
        <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
            <a href="javascript: void(0);" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
          </li> -->
        <li class="nav-item" [ngClass]="{ active: currentSection === 'features' }">
          <a routerLink="/" [ngxScrollTo]="'#features'" class="nav-link">Quem Somos</a>
        </li>
        <li class="nav-item" [ngClass]="{ active: currentSection === 'pricing' }">
          <a routerLink="/" [ngxScrollTo]="'#pricing'" class="nav-link">Nossos Valores</a>
        </li>
        <li class="nav-item" [ngClass]="{ active: currentSection === 'location' }">
          <a routerLink="/" [ngxScrollTo]="'#location'" class="nav-link">Onde Estamos</a>
        </li>
        <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
          <a routerLink="/" [ngxScrollTo]="'#contact'" class="nav-link">Fale Conosco</a>
        </li> -->
        <!-- <li class="nav-item">
          <a target="_blank" href="http://portal.simcombustiveis.com.br/CommonPages/Login.aspx" class="nav-link">Portal do Cliente</a>
        </li> -->
        <li class="nav-item">
          <a target="_blank" href="https://simrede.jobs.recrut.ai/" class="nav-link">Trabalhe na SIM</a>
        </li>
      </ul>
      <!-- <ul class="navbar-nav navbar-center">
        <li class="nav-item">
          <a target="_blank" class="nav-link">
            <i-feather class="icon-xs icon mr-1" name="lock"></i-feather> Portal do Cliente
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="padding-top: 20px">
    <div class="container-flex">
      <!-- <app-slider></app-slider> -->
    </div>
  </section>
  <!-- Hero End -->
  <app-carrossel-inicio></app-carrossel-inicio>
  <app-features></app-features>
  <app-pricing></app-pricing>
  <app-onde-estamos></app-onde-estamos>
  <!-- <app-contact></app-contact> -->
  <app-footer></app-footer>
</div>
