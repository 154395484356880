import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-notfound',
    templateUrl: './not-found.component.html'
})

/**
 * Index-6 component
 */
export class NotFoundComponent implements OnInit {
    ngOnInit(): void {
        //throw new Error('Method not implemented.');
    }
    
}