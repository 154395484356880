<!-- Blog start -->
<section class="section" id="blog">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Latest News</h3>
          <p class="text-muted font-size-15">Et harum quidem rerum facilis est et expedita distinctio nam libero
            tempore cum soluta nobis eligendi cumque.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="blog-box mb-4 mb-lg-0">
          <img src="assets/images/blog/img-1.jpg" alt="" class="img-fluid d-block mx-auto rounded">
          <ul class="list-inline text-muted text-uppercase font-size-15 font-weight-medium mt-3 mb-2">
            <li class="list-inline-item mr-3">
              <i-feather class="icon-size-15 icon mr-1" name="calendar"></i-feather>April 10
              2020
            </li>
            <li class="list-inline-item">
              <i-feather class="icon-size-15 icon mr-1" name="user"></i-feather>Admin
            </li>
          </ul>
          <a href="javascript: void(0);" class="text-dark font-weight-bold h5">Best Traveling Place</a>
          <p class="text-muted font-size-15">Integer ante arcu accumsan a consectetuer eget posuere mauris praesent
            adipiscing phasellus ullamcorper ipsum rutrum punc.</p>
          <a href="javascript: void(0);" class="text-primary font-weight-semibold">Learn More <span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="blog-box blog-box mb-4 mb-lg-0">
          <img src="assets/images/blog/img-2.jpg" alt="" class="img-fluid d-block mx-auto rounded">
          <ul class="list-inline text-muted text-uppercase font-size-15 font-weight-medium mt-3 mb-2">
            <li class="list-inline-item mr-3">
              <i-feather class="icon-size-15 icon mr-1" name="calendar"></i-feather>April 11
              2020
            </li>
            <li class="list-inline-item">
              <i-feather class="icon-size-15 icon mr-1" name="user"></i-feather>Admin
            </li>
          </ul>
          <a href="javascript: void(0);" class="text-dark font-weight-bold h5">Private Meeting Room</a>
          <p class="text-muted font-size-15">Integer ante arcu accumsan a consectetuer eget posuere mauris praesent
            adipiscing phasellus ullamcorper ipsum rutrum punc.</p>
          <a href="javascript: void(0);" class="text-primary font-weight-semibold">Learn More <span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="blog-box blog-box mb-4 mb-lg-0">
          <img src="assets/images/blog/img-3.jpg" alt="" class="img-fluid d-block mx-auto rounded">
          <ul class="list-inline text-muted text-uppercase font-size-15 font-weight-medium mt-3 mb-2">
            <li class="list-inline-item mr-3">
              <i-feather class="icon-size-15 icon mr-1" name="calendar"></i-feather>April 12
              2020
            </li>
            <li class="list-inline-item">
              <i-feather class="icon-size-15 icon mr-1" name="user"></i-feather>Admin
            </li>
          </ul>
          <a href="javascript: void(0);" class="text-dark font-weight-bold h5">The Best Business Ideas</a>
          <p class="text-muted font-size-15">Integer ante arcu accumsan a consectetuer eget posuere mauris praesent
            adipiscing phasellus ullamcorper ipsum rutrum punc.</p>
          <a href="javascript: void(0);" class="text-primary font-weight-semibold">Learn More <span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->
