<!-- Features Start -->
<section class="section feather-bg-img" id="features">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3 text-azul-sim font-weight-bold" style="font-family: Bebas Neue; font-size: 2.2rem">
            Quem Somos
          </h3>
          <h5 class="text-muted" style="font-family: Bebas Neue; font-size: 1.5rem">
            Conheça nossa história
          </h5>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0 block-3">
          <a class="image">
            <img src="assets/images/quem-somos-a.jpeg" alt="" class="img-fluid d-block mx-auto" />
          </a>
        </div>
      </div>

      <div class="col-lg-5 offset-lg-1">
        <p class="text-muted font-size-15 mb-5">
          Tudo começou em 1985. Na época, a pequena Flores da Cunha, localizada
          na Serra Gaúcha, serviu como o berço inicial de toda a trajetória das
          Empresas SIM até os dias de hoje. Foi lá onde o espírito empreendedor
          e o foco no trabalho dos irmãos Deunir e Itacir Neco Argenta
          construíram uma trajetória de grande sucesso.
        </p>

        <p class="text-muted font-size-15 mb-5">
          No dia 12 de outubro de 1985, os irmãos Argenta fundaram a Ditrento
          Comércio e Representações. Na época, o Brasil passava por um momento
          histórico. Era o início da Nova República, e o país voltava a crescer.
          Aproveitando este cenário, iniciou-se então a sociedade de Deunir e
          Itacir numa pequena sala, equipada apenas com escrivaninha, mesa e
          telefone. A estrutura era pequena, mas o sonho da dupla era grande.
        </p>

        <p class="text-muted font-size-15 mb-5">
          Porém, não bastava apenas sonhar alto. Neco e Deunir sabiam disso. Por
          isso, a dedicação e o comprometimento com os resultados foi e tem sido
          uma das vertentes mais importantes da empresa. Passados 27 anos de
          muito trabalho, a Ditrento dá mais um passo importante na sua
          trajetória: agora, a Ditrento Comércio e Representações passaria a se
          chamar SIM Rede de Postos.
        </p>
      </div>
    </div>
  </div>
</section>

<!-- aa -->
<section class="section feather-bg-img pt-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <p class="text-muted font-size-15 mb-5">
          Em junho de 2018, inicialmente estruturados para atender os postos de
          combustíveis da marca SIM, nascia a SIM Distribuidora de Combustíveis,
          gerando sinergia entre as empresas do grupo, além de sermos um grande
          alicerce para o crescimento sustentável da rede.
        </p>

        <p class="text-muted font-size-15 mb-5">
          Em pouco tempo passamos a atender postos de revenda de bandeira
          branca, TRRs e consumidores finais, nos tornando referência em
          qualidade de serviços, produtos e atendimento.
        </p>

        <p class="text-muted font-size-15 mb-5">
          Em 2020 expandimos nossa atuação para toda a região Sul do Brasil.
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-4 mt-lg-0 block-3">
          <a class="image image-2">
            <img src="assets/images/quem-somos-b.jpeg" alt="" class="img-fluid d-block mx-auto" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- aa -->
<section class="section feather-bg-img pt-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0 block-3">
          <a class="">
            <img src="assets/images/trr.jpeg" alt="" class="img-fluid d-block mx-auto" />
          </a>
        </div>
      </div>

      <div class="col-lg-5 offset-lg-1">
        <p class="text-muted font-size-15 mb-5">
          Em 2021, em pesquisa realizada pelo SindTRR, conquistamos o título de
          melhor distribuidora de combustíveis da região Sul, obtendo avaliação
          maior que sete em todas as categorias da pesquisa: Política Comercial,
          Atendimento Comercial, Atividade Operacional das Bases, Atendimento
          Administrativo e Comunicação.
        </p>

        <p class="text-muted font-size-15 mb-5">
          Seguimos em frente, de olho em cada detalhe, com a qualidade e a
          segurança nos movem em sua direção.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Features End -->
