<section class="section bg-light" id="pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3 text-azul-sim font-weight-bold" style="font-family:Bebas Neue;font-size: 2.2rem;">Nossos valores</h3>
          <h5 class="text-muted" style="font-family:Bebas Neue;font-size: 1.5rem;">Nos movem e nos inspiram a fazermos o melhor.</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-12">
        <div class="pricing-box rounded text-center p-4">
          <h4 class="title mb-3 text-verde-sim font-weight-bold" style="font-family:Bebas Neue">Propósito</h4>
          <ul class="list-unstyled pricing-item mb-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-4">
                <div class="mb-4 mb-lg-0">
                  <img src="assets/images/nossos-valores-1.jpg" alt="" class="img-fluid d-block mx-auto" width="120px"
                    height="120px">
                </div>
              </div>

              <div class="col-lg-5">
                <p class="text-muted font-size-15 mb-2">Facilitar a vida das pessoas em movimento todo dia</p>

              </div>
            </div>

          </ul>
        </div>
      </div> -->
      <!-- <div class="col-12">
        <div class="pricing-box rounded text-center p-4">
          <h4 class="title mb-3 text-azul-sim font-weight-bold" style="font-family:Bebas Neue">Missão</h4>
          <ul class="list-unstyled pricing-item mb-4">
            <div class="row align-items-center justify-content-center">

              <div class="col-lg-4">
                <div class="mb-4 mb-lg-0">
                  <img src="assets/images/nossos-valores-2.jpg" alt="" class="img-fluid d-block mx-auto" width="120px"
                    height="120px">
                </div>
              </div>
              <div class="col-lg-5">
                <p class="text-muted font-size-15 mb-2">Proporcionar a melhor experiencia em postos de combustíveis e
                  conveniências
                </p>

              </div>


            </div>
          </ul>
        </div>
      </div> -->
      <div class="col-12">
        <div class="pricing-box rounded text-center p-4">
          <!-- <h4 class="title mb-3 text-azul-sim font-weight-bold" style="font-family:Bebas Neue;font-size: 1.8rem;">Valores</h4> -->
          <ul class="list-unstyled pricing-item mb-4 mt-3">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-4">
                <div class="mb-4 mb-lg-0">
                  <img src="assets/images/nossos-valores-3.jpg" alt="" class="img-fluid d-block mx-auto" width="180px"
                    height="180px">
                </div>
              </div>
              <div class="col-lg-5">
                <li class="text-muted"><b>Ambiente agradável</b><br />Pessoas felizes em ambiente limpo e organizado
                </li>
                <li class="text-muted"><b>Confiança</b><br />Faça correto sempre</li>
                <li class="text-muted"><b>Cuidar das pessoas</b><br />Dignidade, respeito e oportunidade de crescimento
                </li>
                <li class="text-muted"><b>Inovar Sempre</b><br />Fazer mais com menos, inovando sempre, juntos.</li>
                <li class="text-muted"><b>Paixão por servir</b><br />Aqui tem oportunidade para quem quer encantar as
                  pessoas</li>
                <li class="text-muted"><b>Sentimento de dono</b><br />Equipe que se realiza com as conquistas.</li>
              </div>

            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
