export class OndeEstamosModel {
  latitude: number;
  longitude: number;
  dadosUnidade: DadosUnidadeModel;

  constructor(
    dadosUnidade: DadosUnidadeModel,
    latitude: number,
    longitude: number
  ) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.dadosUnidade = dadosUnidade;
  }
}

export class DadosUnidadeModel {
  nome: string;
  endereco: string;
  cidade: string;
  bairro: string;
  estado: string;
  telefone: string;
  complemento: string;
  imagem: string;
  constructor(
    nome: string,
    endereco: string,
    bairro: string,
    cidade: string,
    estado: string,
    telefone: string,
    complemento: string,
    imagem: string
  ) {
    this.nome = nome;
    this.endereco = endereco;
    this.cidade = cidade;
    this.bairro = bairro;
    this.estado = estado;
    this.telefone = telefone;
    this.complemento = complemento;
    this.imagem = imagem;
  }
}
