<!--Navbar Start-->
<app-navbar></app-navbar>
<!-- Navbar End -->

<section class="hero-1-bg bg-white">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6">
                <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">
                    Ooops! Algo de errado aconteceu!
                </h1>
                <div class="w-75 mb-lg-0">
                    <p class="text-muted pb-5 font-size-17">
                        A página solicitada não foi encontrada, desculpe pelo transtorno...
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class=" mt-5 mt-lg-0">
                    <img src="assets/images/icon-404.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
    </div>
</section>

<!--Footer Start-->
<app-footer></app-footer>
<!-- Footer End -->