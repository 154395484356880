<!-- Contact Us Start -->
<section class="section" id="contact">
  <div class="container">
    <div class="row"></div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3 text-azul-sim font-weight-bold" style="font-family:Bebas Neue">Preparado para ir além?
          </h3>
          <h5 class="text-muted font-weight-bold" style="font-family:Bebas Neue">
            Fale conosco pelos canais abaixo.
          </h5>
        </div>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col-lg-7">
        <div class="custom-form mb-5 mb-lg-0">
          <div id="message"></div>
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="nome" class="text-azul-sim">Nome*</label>
                  <input formControlName="nome" type="text" class="form-control" autocomplete="off"
                    placeholder="Informe seu nome..." [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                  <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                    <div *ngIf="f.nome.errors.required">Nome é obrigatório</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="telefone" class="text-azul-sim">Telefone*</label>
                  <input formControlName="telefone" type="text" class="form-control" autocomplete="off"
                    placeholder="Informe seu telefone..."
                    [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }" />
                  <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                    <div *ngIf="f.telefone.errors.required">Telefone é obrigatório</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="input-wrapper">
                    <label for="email" class="text-azul-sim">E-mail*</label>
                    <input formControlName="email" type="email" class="form-control" autocomplete="off"
                      placeholder="Informe seu e-mail..." [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">E-mail é obrigatório</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="mensagem" class="text-azul-sim">Mensagem*</label>
                  <textarea formControlName="mensagem" rows="4" class="form-control"
                    placeholder="Informe sua mensagem..."
                    [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
                  <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                    <div *ngIf="f.mensagem.errors.required">Mensagem é obrigatório</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="formInvalid">
              <div class="col-lg-12">
                <div class="form-group">
                  <label style="color: red;">{{errorMessage}}</label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="sendSuccess">
              <div class="col-lg-12">
                <div class="form-group">
                  <label style="color: green;">Obrigado pelo contato! Receberemos sua mensagem e responderemos em
                    breve.</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 text-center">
                <button type="submit" class="btn btn-verde-sim">Enviar mensagem <i-feather
                    class="icon-size-15 ml-2 icon" name="send"></i-feather></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-5 pt-4">
        <div class="contact-detail text-muted ml-lg-5">
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="mail"></i-feather> <span>contato@simcombustiveis.com.br</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="link"></i-feather> <span>www.simcombustiveis.com.br</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="phone-call"></i-feather> <span>(51) 3473-1366</span>
          </p>
          <p style="margin-bottom: 0;">
            <i-feather class="icon-xs icon mr-1" name="map-pin"></i-feather> <span>Av. Presidente Vargas, 3700, Sala
              04</span>
          </p>
          <p style="margin-bottom: 0;">
            <i-feather class="icon-xs icon mr-1" name=""></i-feather>&nbsp;&nbsp;&nbsp;<span>São Sebastião - Esteio /
              RS</span>
          </p>
          <p>
            <i-feather class="icon-xs icon mr-1" name=""></i-feather>&nbsp;&nbsp;&nbsp;<span>93.265-542</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->
