<!-- Footer Start -->
<!-- <section class="footer" style="background-image: url(assets/images/footer-bg.png)"> -->
<section class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div cl ass="mb-5">

          <img src="assets/images/logo-distri.png" alt="" class="logo-dark" height="100" />

          <!-- <h6 class="text-white font-weight-bold text-uppercase mb-4">SIM Distribuidora</h6> -->
          <p class="my-4 font-size-15 text-white">
            Conecte-se conosco.
          </p>
          <ul class="list-inline footer-social-icon-content">
            <!-- <li class="list-inline-item mr-4"><a href="https://pt-br.facebook.com/Simredepostos" target="_blank"
                class="footer-social-icon">
                <i-feather name="facebook"></i-feather>
              </a></li> -->
            <!-- <li class="list-inline-item mr-4"><a href="https://instagram.com/simredepostos" target="_blank"
                class="footer-social-icon">
                <i-feather name="instagram"></i-feather>
              </a></li> -->
            <li class="list-inline-item mr-4"><a
                href="https://www.linkedin.com/company/sim-distribuidora-de-combust%C3%ADveis/" target="_blank"
                class="footer-social-icon">
                <i-feather name="linkedin"></i-feather>
              </a></li>
            <!-- <li class="list-inline-item mr-4"><a href="https://www.youtube.com/channel/UCkryjzIdFx-GLCyn9g57SFQ"
                target="_blank" class="footer-social-icon">
                <i-feather name="youtube"></i-feather>
              </a></li> -->
          </ul>
        </div>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="row">
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <h6 class="text-white font-weight-bold text-uppercase mb-3">Informações</h6>
            <ul class="list-unstyled footer-sub-menu text-white">
              <!-- <li><a href="javascript: void(0);" class="footer-link">Termos e Condições</a></li> -->
              <li><a href="javascript: void(0);" class="footer-link" (click)="open(content)">Política de Privacidade</a>
              </li>
            </ul>
            <h6 class="text-white font-weight-bold text-uppercase mb-3">Contatos</h6>
            <ul class="list-unstyled footer-sub-menu text-white">
              <!-- <li><a href="javascript: void(0);" class="footer-link">Termos e Condições</a></li> -->
              <li><a href="javascript: void(0);" class="footer-link">(51) 3473-1366</a>


              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="footer-alt py-3">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="text-left">
          <p class="font-size-13">{{year}} © SIM Distribuidora. Todos os direitos reservados.</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="text-right">
          <p class="font-size-12"><a href="https://tc2code.com.br" target="_blank" class="footer-alt">TC2CODE</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Politicas de Privacidade</h4>
    <a class="footer-link" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <form *ngFor="let politicaPrivacidade of PoliticaDePrivacidade">
      <div class="form-group">
        <div [innerHtml]="politicaPrivacidade.PoliticaPrivacidade"></div>

      </div>
    </form>
  </div>

</ng-template>
