import { Component, OnInit } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { styles } from "./mapstyles";

import {
  DadosUnidadeModel,
  OndeEstamosModel,
} from "../../models/onde-estamos.model";

@Component({
  selector: "app-onde-estamos",
  templateUrl: "./onde-estamos.component.html",
  styleUrls: ["./onde-estamos.component.scss"],
})
export class OndeEstamosComponent implements OnInit {
  title = "google-maps";
  private map: google.maps.Map;

  unidades: OndeEstamosModel[] = [
    new OndeEstamosModel(
      new DadosUnidadeModel(
        "MATRIZ - ESTEIO",
        "Av. Presidente Vargas, 3700",
        "São Sebastião",
        "Esteio",
        "RS",
        "51-34731366",
        "Opção 1 - Faturamento / opção 2 - Comercial",
        "matrizEsteio.jpeg"
      ),
      -29.8636245,
      -51.1806263
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - RIO GRANDE",
        " Av. Engenheiro Francisco Mastins Bastos, 202 - Sala J",
        "Vila Santa Tereza",
        "Rio Grande",
        "RS",
        "51 - 34731366",
        "Opção 4",
        "filialRiogrande.jpeg"
      ),
      -32.047349,
      -52.085937
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - ITAJAÍ TRANSPETRO",
        " Rua Benjamin Dagnone, 900",
        "Rio do Meio",
        "Itajaí",
        "SC",
        "51 - 34731366",
        "Opção 6",
        "filialItajaiTranspetro.jpeg"
      ),
      -26.9496272,
      -48.7135607
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - ITAJAÍ STANG",
        " Rua Benjamin Dagnone, 1300",
        "Rio do Meio",
        "Itajaí",
        "SC",
        "51 - 34731366",
        "Opção 6",
        "filialItajaiStang.jpeg"
      ),
      -26.9545912,
      -48.7128682
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - ARAUCÁRIA",
        "Rua Dr Eli Volpato 948, sala 6",
        "Chapada",
        "Araucária",
        "PR",
        "51 - 34731366",
        "Opção 9",
        "filialAraucaria.jpeg"
      ),
      -25.5733837,
      -49.3728659
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - COR. BARROS",
        "BR285 KM 477 - Sala 04",
        "Interior",
        "Coronel Barros",
        "RS",
        "51 - 34731366",
        "Opção 6",
        "filialCorBarros.jpeg"
      ),
      -28.38159,
      -54.065387
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - GUARAMIRIM",
        "Rua Marcionilo dos Santos, 86 - Sala 03",
        "Corticeira",
        "Guaramirim",
        "SC",
        "51 - 34731366",
        "Opção 7",
        "filialGuaramim.jpeg"
      ),
      -26.4629161,
      -48.9188572
    ),

    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - SARANDI",
        "Av Castelo Branco, 800 - Sala 115",
        "Vera Cruz",
        "Sarandi",
        "PR",
        "51 - 34731366",
        "Opção 10",
        "filialSarandi.jpeg"
      ),
      -23.4644597,
      -51.859456
    ),
    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - LONDRINA",
        "Rua Alcenir Bueno, 155 ",
        "Cilo 3",
        "Londrina",
        "PR",
        "51 - 34731366",
        "Opção 11",
        "filialLondrina.jpeg"
      ),
      -23.2779818,
      -51.21405
    ),
    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - CHAPECÓ",
        "Rodovia SC 480, KM 5.8, 4445 E",
        "Distrito Industrial",
        "Chapecó",
        "SC",
        "51 - 34731366",
        "Opção 8",
        "filialChapeco.jpeg"
      ),
      -27.1729304,
      -52.6359172
    ),
    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - SÃO PAULO",
        "Avenida Almirante Delamare, 1335",
        "Heliópolis",
        "São Paulo",
        "SP",
        "51 - 34731366",
        "",
        "filialSaoPaulo.jpg"
      ),
      -23.6062298,
      -46.5935714
    ),
    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - BIGUAÇU",
        "Rua José Braz da Silva, S/N Sala 02",
        "Alto Biguaçu",
        "Biguaçu",
        "SC",
        "51 - 34731366",
        "",
        "filialBiguacu.jpg"
      ),
      -27.4996537,
      -48.7083057
    ),
    new OndeEstamosModel(
      new DadosUnidadeModel(
        "FILIAL - PAULÍNIA",
        "Av. Sidney Cardon de Oliveira, 1325",
        "Cascata",
        "Paulínia",
        "SP",
        "51 - 34731366",
        "",
        "filialPaulinia.jpg"
      ),
      -22.7315694,
      -47.1532248
    ),
  ];
  estados: any = [
    {
      nome: "Rio Grande do Sul",
      sigla: "RS",
    },
    { nome: "Paraná", sigla: "PR" },
    { nome: "Santa Catarina", sigla: "SC" },
    { nome: "São Paulo", sigla: "SP" },
  ];

  cidades: any = [
    { nome: "Esteio", sigla: "RS" },
    { nome: "Rio Grande", sigla: "RS" },
    { nome: "Itajaí", sigla: "SC" },
    { nome: "Araucária", sigla: "PR" },
    { nome: "Coronel Barros", sigla: "RS" },
    { nome: "Guaramirim", sigla: "SC" },
    { nome: "Sarandi", sigla: "PR" },
    { nome: "Londrina", sigla: "PR" },
    { nome: "Chapecó", sigla: "SC" },
    { nome: "São Paulo", sigla: "SP" },
    { nome: "Biguaçu", sigla: "SC" },
    { nome: "Paulínia", sigla: "SP" },
  ];

  cidadesCombo: any = [];

  zoom: number = 6;
  latitude: number = -27.021104;
  longitude: number = -50.919355;

  filtroNome: any = null;
  filtroCidade: any = null;
  filtroEstados: any = null;

  ngOnInit(): void {
    this.mapa(this.unidades, this.zoom, this.latitude, this.longitude, true);
  }

  filtroPesquisaEstado(filtro: string) {
    const index = this.unidades.filter(
      (category) => category.dadosUnidade.estado === filtro
    );

    // console.log(index);

    if (index) {
      if (filtro === "RS") {
        this.mapa(index, 7, -30.3993433, -55.918667, false);

        this.cidadesCombo = [];
        this.cidades.forEach((element: any) => {
          if (element.sigla === filtro) {
            this.cidadesCombo.push(element);
          }
        });
      } else if (filtro === "PR") {
        this.mapa(index, 7, -24.6125343, -52.4453116, false);

        this.cidadesCombo = [];
        this.cidades.forEach((element: any) => {
          if (element.sigla === filtro) {
            this.cidadesCombo.push(element);
          }
        });
      } else if (filtro === "SC") {
        this.mapa(index, 7, -27.6509222, -52.2067422, false);

        this.cidadesCombo = [];
        this.cidades.forEach((element: any) => {
          if (element.sigla === filtro) {
            this.cidadesCombo.push(element);
          }
        });
      } else if (filtro === "SP") {
        this.mapa(index, 7, -23.5995763, -46.581005, false);

        this.cidadesCombo = [];
        this.cidades.forEach((element: any) => {
          if (element.sigla === filtro) {
            this.cidadesCombo.push(element);
          }
        });
      }
    } else {
      this.mapa(this.unidades, this.zoom, this.latitude, this.longitude, true);
    }
  }
  filtroPesquisaCidade(filtro: string) {
    const index = this.unidades.filter(
      (category) => category.dadosUnidade.cidade === filtro
    );

    console.log(index);
    if (index) {
      if (filtro === "Esteio") {
        this.mapa(index, 10, -29.8498873, -51.1659441, false);
      } else if (filtro === "Rio Grande") {
        this.mapa(index, 10, -32.2088942, -52.6603173, false);
      } else if (filtro === "Itajaí") {
        this.mapa(index, 10, -26.967523, -48.8153779, false);
      } else if (filtro === "Araucária") {
        this.mapa(index, 10, -25.5743495, -49.4207181, false);
      } else if (filtro === "Coronel Barros") {
        this.mapa(index, 10, -28.3820281, -54.0829468, false);
      } else if (filtro === "Guaramirim") {
        this.mapa(index, 10, -26.4737004, -49.0116653, false);
      } else if (filtro === "Sarandi") {
        this.mapa(index, 10, -23.4416438, -51.8883964, false);
      } else if (filtro === "Londrina") {
        this.mapa(index, 10, -23.3212639, -51.2359745, false);
      } else if (filtro === "Chapecó") {
        this.mapa(index, 10, -27.0757047, -52.7160138, false);
      } else if (filtro === "São Paulo") {
        this.mapa(index, 10, -23.6076082, -46.5921386, false);
      } else if (filtro === "Biguaçu") {
        this.mapa(index, 10, -27.4996537, -48.7083057, false);
      } else if (filtro === "Paulínia") {
        this.mapa(index, 10, -22.7315694, -47.1532248, false);
      }
    } else {
      this.mapa(this.unidades, this.zoom, this.latitude, this.longitude, true);
    }
  }
  filtroPesquisaNome(filtro: string) {
    const index = this.unidades.filter(
      (category) => category.dadosUnidade.nome === filtro
    );

    if (index) {
      if (filtro === "MATRIZ - ESTEIO") {
        this.mapa(index, 15, -29.8636245, -51.1806263, false);
      } else if (filtro === "FILIAL - RIO GRANDE") {
        this.mapa(index, 15, -32.047349, -52.085937, false);
      } else if (filtro === "FILIAL - ITAJAÍ TRANSPETRO") {
        this.mapa(index, 15, -26.9496272, -48.7135607, false);
      } else if (filtro === "FILIAL - ITAJAÍ STANG") {
        this.mapa(index, 15, -26.9545912, -48.7128682, false);
      } else if (filtro === "FILIAL - ARAUCÁRIA") {
        this.mapa(index, 15, -25.5733837, -49.3728659, false);
      } else if (filtro === "FILIAL - COR. BARROS") {
        this.mapa(index, 15, -28.38159, -54.065387, false);
      } else if (filtro === "FILIAL - GUARAMIRIM") {
        this.mapa(index, 15, -26.4629161, -48.9188572, false);
      } else if (filtro === "FILIAL - SARANDI") {
        this.mapa(index, 15, -23.4644597, -51.859456, false);
      } else if (filtro === "FILIAL - LONDRINA") {
        this.mapa(index, 15, -23.2779818, -51.21405, false);
      } else if (filtro === "FILIAL - CHAPECÓ") {
        this.mapa(index, 15, -27.1729304, -52.6359172, false);
      } else if (filtro === "FILIAL - SÃO PAULO") {
        this.mapa(index, 15, -23.6062298, -46.5935714, false);
      } else if (filtro === "FILIAL - BIGUAÇU") {
        this.mapa(index, 15, -27.4996537, -48.7083057, false);
      } else if (filtro === "FILIAL - PAULÍNIA") {
        this.mapa(index, 15, -22.7315694, -47.1532248, false);
      }
    } else {
      this.mapa(this.unidades, this.zoom, this.latitude, this.longitude, true);
    }
  }

  mapa(
    arrayUnidade: OndeEstamosModel[],
    zoom: number,
    latitude: number,
    longitude: number,
    limparCampos: boolean
  ) {
    let loader = new Loader({
      apiKey: "AIzaSyBQp8Aa8ybIAdIu7qiG4Mp-ysD20C16F4U",
    });

    loader.load().then(() => {
      var map = new google.maps.Map(document.getElementById("map"), {
        center: new google.maps.LatLng(latitude, longitude),
        zoom: zoom,
        styles: styles,
      });

      if (limparCampos === true) {
        this.filtroCidade = null;
        this.filtroEstados = null;
        this.filtroNome = null;
      }

      for (let i = 0; i < arrayUnidade.length; i++) {
        const unidade = arrayUnidade[i];

        const callUnidade =
          '<div id="siteNotice">' +
          "</div>" +
          '<img src="../../../assets/images/onde-estamos/' +
          unidade.dadosUnidade.imagem +
          '" alt=""  width="300" height="200" >' +
          '<div id="bodyContent">' +
          "<p><b>" +
          unidade.dadosUnidade.nome +
          "</b> <br/>" +
          unidade.dadosUnidade.endereco +
          "<br/>" +
          "Bairro :" +
          unidade.dadosUnidade.bairro +
          "<br/> " +
          unidade.dadosUnidade.cidade +
          " - " +
          unidade.dadosUnidade.estado +
          " <br/> " +
          "Fone :" +
          unidade.dadosUnidade.telefone +
          "<br/>" +
          unidade.dadosUnidade.complemento +
          "<br/>" +
          "</div>" +
          "</div>";

        var marker = new MarkerWithLabel({
          position: new google.maps.LatLng(unidade.latitude, unidade.longitude),
          draggable: false,
          map: map,
          icon: "../../../assets/images/simPin.png",
          labelContent: "",
          labelAnchor: new google.maps.Point(22, 0),
        });

        var infoWindow = new google.maps.InfoWindow({
          content: callUnidade,
        });
        // var infoWindow = new google.maps.InfoWindow();

        marker.addListener("click", function () {
          infoWindow.setContent(callUnidade);
          infoWindow.open(map, this);
        });
      }
    });
  }
}
