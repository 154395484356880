<div class="carouselCont">
  <ngb-carousel class="carouselSlide">
    <!-- <ng-template *ngFor="let carousel of Carousels" ngbSlide>
        <div class="picsum-img-wrapper">
          <img   [src]="carousel.carouselImagens"  class="d-block w-100"  alt="Random first slide">
          <p class="carousel-caption"> TESTE </p>
        </div>
        <div class="carousel-caption">
        </div>
      </ng-template> -->

    <ng-template *ngFor="let imagens of imagens" ngbSlide>
      <div class="picsum-img-wrapper">
        <img
          src="{{ imagens.img }}"
          class="d-block w-100"
          alt="Random first slide"
        />
      </div>
      <div class="carousel-caption"></div>
    </ng-template>
  </ngb-carousel>
</div>
