import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { PoliticaDePrivacidade } from "../../admin/politicas-de-privacidade/interface-politica-de-privacidade/politica-de-privacidade";
import { PoliticasDePrivacidadeService } from "../../admin/politicas-de-privacidade/politicas-de-privacidade.service";
import { DomSanitizer } from "@angular/platform-browser";
import { first } from "rxjs/operators";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  closeResult = "";
  PoliticaDePrivacidade: PoliticaDePrivacidade[];
  politicaPrivacidade: any;
  submitted = false;

  year: number = new Date().getFullYear();
  constructor(
    private modalService: NgbModal,
    private politicaDePrivacidadeService: PoliticasDePrivacidadeService,

    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}
  open(content: any) {
    this.politicaDePrivacidadeService
      .getAll()
      .pipe(first())
      .subscribe((politicaPrivacidade: PoliticaDePrivacidade[]) => {
        this.PoliticaDePrivacidade = politicaPrivacidade;
      });

    const modal = this.modalService
      .open(content, {
        backdrop: "static",
        size: "lg",
        keyboard: false,
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    modal["data"] = this.PoliticaDePrivacidade;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
